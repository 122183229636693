import { Injectable } from '@angular/core';
import { Color, RGB, HEX } from './app.color';
import 'sass-to-js/js/src/sass-to-js.js';
import { environment } from '../environments/environment';
@Injectable()
export class AppConfig {

  public sassVariables: any;
  public config: any;
  public headers: any;
  public statusArray: any = [];
  public CONFIG_DATA: any;
  authorization_type:any
  public SENSOR_TYPES: any;
  public ALERTS_TYPES:any;
  public USER_ROLES: any = {};
  public tenantUserRoles: any = [];
  public dataTypesArray: any = [];
  public RULES;
  constructor() {
    let brandName = environment.brandName;
    // let clientName = environment.clientName;
    // let appId = environment.app.id;
    // let appKey = environment.app.key;
    // let url = environment.app.url;
    // let murl = environment.app.murl

    // let googleMapKey = environment.googleMapKey;
    // let debugEnabled = environment.debugEnabled;

    // this.CONFIG_DATA = {
    //   BRAND_NAME: brandName,
    //   APP_ID: appId,
    //   APP_KEY: appKey,
    //   APP_URL: url,
    //   Mobile_App_url: murl,
    //   // SERVER_URL: url + "/apps/" + appId + "/server-code/versions/current/",
    //   // SERVER_URL: "http://"+clientName+".aitestpro.com:9000",
    //   SERVER_ROOT_URL: url + '/apps/' + appId + '/',
    //   BROADCAST_URL: '//run-east.att.io/1ce9979c48ff8/60b04e64ee05/f1d1cb27ea8213a/in/flow/sendMessage',
    //   TRUE: true,
    //   FALSE: false,
    // }
    // this.headers = {
    //   'X-Kii-AppID': appId,
    //   'X-Kii-AppKey': appKey,
    //   'Content-Type': "application/json",
    //   'Accept': "application/json"
    // }
    this.authorization_type = {
      'username':'user',
      'password':'password'
     }


    this.sassVariables = this.getSassVariables();
    this.config = {
      name: brandName,
      title: 'groupBenfitz',
      version: '1.0.3',
      colors: {
        main: this.sassVariables['main-color'],
        default: this.sassVariables['default-color'],
        dark: this.sassVariables['dark-color'],
        primary: this.sassVariables['primary-color'],
        info: this.sassVariables['info-color'],
        success: this.sassVariables['success-color'],
        warning: this.sassVariables['warning-color'],
        danger: this.sassVariables['danger-color'],
        sidebarBgColor: this.sassVariables['sidebar-bg-color'],
        gray: this.sassVariables['gray'],
        grayLight: this.sassVariables['gray-light']
      }
    }



  ;
  }


  getSassVariables() {
    let variables = jQuery('body').sassToJs({ pseudoEl: "::after", cssProperty: "content" });
    return variables;
  }

  rgba(color, opacity) {
    if (color.indexOf('#') >= 0) {
      if (color.slice(1).length == 3) {
        color = '#' + color.slice(1) + '' + color.slice(1);
      }
      return new Color(new HEX(color)).setAlpha(opacity).toString();
    }
    else {
      console.log("incorrect color: " + color);
      return 'rgba(255,255,255,0.7)';
    }
  }

}
