<ng-template #customLoadingTemplate>
     <div class="loader-overlay">
       <div class="spinner">
         <div class="spin-outer" id="preloader">
           <div class="spin-container">
             <div class="spin-inner">
               <div class="spin-cube-grid">
                 <!-- <div class="spin-cube spin-cube1" style="background: url('assets/img/logo/cube-1.png');  background-size: cover;"></div> -->
                 <div class="spin-cube spin-cube1" style="background: url('assets/img/logo/cube1.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube2" style="background: url('assets/img/logo/cube2.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube3" style="background: url('assets/img/logo/cube3.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube4" style="background: url('assets/img/logo/cube4.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube5" style="background: url('assets/img/logo/cube5.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube6" style="background: url('assets/img/logo/cube6.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube7" style="background: url('assets/img/logo/cube7.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube8" style="background: url('assets/img/logo/cube8.png');  background-size: cover;"></div>
                 <div class="spin-cube spin-cube9" style="background: url('assets/img/logo/cube9.png');  background-size: cover;"></div>

               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </ng-template>
   <ngx-loading [show]="show" [config]="{ backdropBorderRadius: '10px',fullScreenBackdrop:true}" [template]="customLoadingTemplate"></ngx-loading>
