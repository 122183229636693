<router-outlet></router-outlet>
  <az-loader></az-loader>
  <div class="modal fade" id="sessionExpired-modal" tabindex="-1" role="dialog" aria-labelledby="modal-label"
  data-backdrop="static" data-keyboard="false" style="display: none;">
  <div class="modal-dialog modal-md" role="document"> <div class="modal-content">
   <div class="modal-header text-center"> <h4 class="modal-title custom-font" id="modal-label">
   <strong>Session</strong>&nbsp;Expired</h4> </div> <div class="modal-body text-center">
    <p><strong>Your session has expired. Please log in again</strong></p>
    </div> <div class="modal-footer">
    <button type="button" class="btn btn-success btn-sm" (click)="sessionPromptClose()">
    <i class="fa fa-arrow-right"></i> OK</button> </div> </div> </div> </div>